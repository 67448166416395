import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect, useRef } from "react";
import { Button, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TakeDownCard from "../../Components/TakeDownRequests/TakeDownCard.jsx";
import ConditionModal from "../../Components/TakeDownRequests/ConditionModal.jsx";
import TakedownForm from "../../Components/TakeDownRequests/TakedownForm.jsx";
import {
  getAddedAssetTakeDownRequestAction,
  resetDMCAError,
} from "../../Store/DMCA/actions";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";
import WebsiteDialog from "../../Components/Common/WebsiteDialog.jsx";

const useStyles = makeStyles((theme) => ({
  takeDownContainer: {
    height: "100%",
    overflowY: "auto",
  },
  bodyContainer: {
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },

  icon: {
    height: "100px",
    width: "100px",
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  errorContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0 10px",
    "& h2": {
      ...theme.typography.heading32,
      fontWeight: 500,
      margin: 0,
      lineHeight: "40px",
      marginBottom: "20px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
  },
  greenButton: {
    ...theme.typography.button,
    color: `${theme.palette.white} !important`,
    background: `${theme.palette.lightGreen} !important`,
    padding: "0 25px !important",
    margin: "5px",
    marginTop: "10px",
    height: "55px",
    textTransform: "none",
    "&:hover": {
      background: theme.palette.darkGreen,
    },
  },
}));

const TakedownRequests = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState({ pageNumber: 1, perPage: 50 });
  const scrollContainerRef = useRef(null);

  const { userData } = useSelector((state) => state.user);
  const {
    addedRequests,
    loading,
    error,
    errorMessage,
    errorTitle,
    totalRecords,
    dataLength,
  } = useSelector((state) => state.DMCA);

  const [showConditionsModal, setShowConditionsModal] = useState(false);
  const [websiteModal, setwebsiteModal] = useState(false);

  const [selectedImage, setSelectedImage] = useState();

  const showForm = () => {
    if (
      !userData?.country ||
      !userData?.city ||
      !userData?.street ||
      !userData?.zip_code ||
      !userData?.state ||
      !userData?.full_name
    ) {
      return true;
    }
    return false;
  };

  const openConditionsModal = (e) => {
    setSelectedImage(e);
    setShowConditionsModal(true);
  };

  const closeConditionsModal = () => {
    setShowConditionsModal(false);
  };

  const openWebsiteModal = (website) => {
    setwebsiteModal(website);
  };

  const closeWebsiteModal = () => {
    setwebsiteModal(false);
  };

  const handleClick = () => {
    navigate("/account/billing?reason=takedown");
  };

  useEffect(() => {
    if (addedRequests.length === 0) {
      dispatch(getAddedAssetTakeDownRequestAction(query));
    }
    return () => dispatch(resetDMCAError());
  }, []);

  const handleScroll = () => {
    if (Number(dataLength) !== 0 && !loading) {
      if (scrollContainerRef.current) {
        const {
          scrollTop,
          scrollHeight,
          clientHeight,
        } = scrollContainerRef.current;
        if (
          scrollHeight - Math.round(scrollTop + clientHeight) === 0 ||
          scrollHeight - Math.round(scrollTop + clientHeight) === -1
        ) {
          dispatch(
            getAddedAssetTakeDownRequestAction({
              ...query,
              pageNumber: query.pageNumber + 1,
            })
          );
          setQuery({ ...query, pageNumber: query.pageNumber + 1 });
          // return setPage(page + 1);
        }
      }
    }
  };

  return (
    <>
      <WebsiteDialog open={websiteModal} onClose={closeWebsiteModal} />
      {showForm() ? (
        <TakedownForm userData={userData} />
      ) : loading && addedRequests.length === 0 ? (
        <div className={classes.loaderContainer}>
          <CustomSpinner />
        </div>
      ) : error ? (
        <div className={classes.errorContainer}>
          <h2>{errorMessage}</h2>

          {errorTitle && (
            <Button className={classes.greenButton} onClick={handleClick}>
              {errorTitle}
            </Button>
          )}
        </div>
      ) : (
        <div
          className={classes.takeDownContainer}
          ref={scrollContainerRef}
          onScroll={handleScroll}
        >
          <ConditionModal
            open={showConditionsModal}
            onClose={closeConditionsModal}
            selectedImage={selectedImage}
          />
          {/* <Filter /> */}
          <div
            style={{
              textAlign: "end",
              paddingTop: "20px",
              paddingBottom: "20px",
              color: "grey",
              fontSize: "18px",
            }}
          >
            <b>{Number(totalRecords)}</b> Records found
          </div>
          <Grid container spacing={2} className={classes.bodyContainer}>
            {addedRequests?.map((e, i) => (
              <Grid item xs={12} sm={4} md={3} lg={3} key={i}>
                <TakeDownCard
                  image={
                    e?.match_details?.source_type === "image"
                      ? e.match_details?.image_url
                      : e.match_details?.frameSignedUrl
                  }
                  faceUrl={e.match_details?.faceSignedUrl}
                  website={e.match_details?.domain}
                  openConditionsModal={() => openConditionsModal(e)}
                  status={e?.status}
                  openModal={() => openWebsiteModal(e?.match_details)}
                />
              </Grid>
            ))}
          </Grid>
          {dataLength !== 0 && addedRequests.length >= query.perPage && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <CustomSpinner />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default TakedownRequests;
