import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Grid,
  // IconButton,
  // InputAdornment,
  // TextField,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import clsx from "clsx";
import moment from "moment";
import {
  // MdMoreHoriz,
  MdOutlineArrowBackIos,
} from "react-icons/md";
// import AttachIcon from "../../Assets/Images/attach_icon.png";
// import SendIcon from "../../Assets/Images/send_icon.png";
import NoMessageIcon from "../../Assets/Images/no_message_icon.png";
import CustomSpinner from "../UI/CustomSpinner.jsx";
import { getInitials } from "../../Helpers/functions";
// import { sendReplyAction } from "../../Store/DMCA/actions";

const useStyles = makeStyles((theme) => ({
  dmcaInboxContainer: {
    height: "100%",
    background: theme.palette.white,
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.05)",
  },

  headContainer: {
    display: "flex",
    padding: "20px",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: `1px solid ${theme.palette.grey4}`,
  },
  headMobile: {
    padding: "20px",
    borderBottom: `1px solid ${theme.palette.grey4}`,
  },
  avatarInfo: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    fontWeight: "500 !important",
    fontSize: "14px !important",
    lineHeight: "17px !important",
    backgroundColor: `${theme.palette.lightGreen} !important`,
  },
  messageAvatar: {
    fontWeight: "500 !important",
    fontSize: "20px !important",
    lineHeight: "24px !important",
    marginRight: "20px",
  },

  textBox: {
    marginLeft: "20px",
  },
  mobileAvatarContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  backIcon: {
    fontSize: "18px",
  },
  contentHead: {
    ...theme.typography.body16,
    fontWeight: 700,
    margin: 0,
    marginBottom: "5px",
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  contentbody: {
    ...theme.typography.body14,
    color: theme.palette.grey2,
    margin: 0,
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  moreIcon: {
    color: theme.palette.black,
    fontSize: "30px",
  },
  bodyContainer: {
    height: "calc(100% - 88px)",
  },
  body: {
    height: "100%",
    // padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  fileInput: {
    display: "none",
  },
  inputContainer: {
    display: "flex",
    alignItems: "center",
    padding: "10px 20px",
  },
  clipIcon: {
    height: "22px",
    width: "22px",
    padding: "10px 20px 10px 0",
    cursor: "pointer",
  },
  messageInput: {
    width: "100%",
    "& .MuiOutlinedInput-notchedOutline": {
      border: `1px solid ${theme.palette.grey5}`,
    },
    "& input": {
      padding: "15px",
      "&::placeholder": {
        fontSize: "16px",
        color: theme.palette.grey2,
        opacity: 1,
      },

      "&:-ms-input-placeholder": {
        fontSize: "16px",
        opacity: 1,
        color: theme.palette.grey2,
      },

      "&::-ms-input-placeholder": {
        fontSize: "16px",
        color: theme.palette.grey2,
        opacity: 1,
      },
      fontSize: "16px",
    },
  },
  // sendIconButton: {
  //   background: theme.palette.white,
  //   border: "none",
  // },
  messagesContainerOuter: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    overflow: "hidden",
  },
  messagesContainerInner: {
    maxHeight: "100%",
    overflowY: "auto",
  },
  message: {
    padding: "0 20px",
    margin: "10px 0",
    display: "flex",
  },
  messageContent: {
    flex: "1",
    "& h3": {
      margin: 0,
      ...theme.typography.body16,
      fontWeight: "700",

      "& span": {
        ...theme.typography.body14,
        marginLeft: "10px",
        color: theme.palette.grey2,
      },
    },
  },
  messageBodyContainer: {
    marginTop: "5px",
    width: "100%",
  },
  messageBody: {
    background: theme.palette.grey4,
    padding: "15px 20px",
    borderRadius: "15px",
    "& p": {
      margin: "13px 0",
      ...theme.typography.body18,
      lineHeight: "24px",
    },
  },
  line: {
    padding: "15px",
    "& h2": {
      ...theme.typography.body14,
      color: theme.palette.grey2,
    },
  },
  noMessageContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",

    "& h2": {
      margin: 0,
      marginTop: "10px",
      fontSize: "36px",
      lineHeight: "40px",
      fontWeight: 700,
      textAlign: "center",
    },
    "& h3": {
      margin: 0,
      marginTop: "5px",
      fontSize: "24px",
      lineHeight: "38px",
      fontWeight: 400,
      textAlign: "center",
    },
  },
  redAvatar: {
    backgroundColor: `${theme.palette.red2} !important`,
  },
  yellowAvatar: {
    background: `${theme.palette.yellow} !important`,
    color: `${theme.palette.black} !important`,
  },
  spinnerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
}));

const DMCAMessages = ({
  selectedRequest,
  data,
  handleMessageBack,
  emailLoading,
}) => {
  const classes = useStyles();
  // const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  // const [helperText, setHelperText] = useState("");
  // const [replyMessage, setReplyMessage] = useState("");

  // const handleChangeMessage = (e) => setReplyMessage(e.target.value);
  // const sendReply = () => {
  //   dispatch(
  //     sendReplyAction({ body: replyMessage, takedown_id: selectedRequest._id })
  //   );
  //   setReplyMessage("");
  // };
  return (
    <div className={classes.dmcaInboxContainer}>
      {emailLoading ? (
        <div className={classes.spinnerContainer}>
          <CustomSpinner />
        </div>
      ) : (
        <>
          {data?.length > 0 ? (
            isMobileScreen ? (
              <Grid
                container
                justifyContent="space-between"
                flexDirection="row"
                alignItems="center"
                className={classes.headMobile}
              >
                <Grid item xs={2}>
                  <MdOutlineArrowBackIos
                    onClick={handleMessageBack}
                    className={classes.backIcon}
                  />
                </Grid>
                <Grid item xs={8}>
                  <h3 className={classes.contentHead}>
                    {moment(data[data.length - 1]?.createdAt).fromNow()}
                  </h3>
                  <p className={classes.contentbody}>
                    {moment(data[data.length - 1]?.createdAt).fromNow()}
                  </p>
                </Grid>
                <Grid item xs={2}>
                  <div className={classes.mobileAvatarContainer}>
                    <Avatar className={classes.avatar}>
                      {getInitials(selectedRequest?.domain_id)}
                    </Avatar>
                  </div>
                </Grid>
              </Grid>
            ) : (
              <div className={classes.headContainer}>
                <div className={classes.avatarInfo}>
                  <Avatar className={classes.avatar}>
                    {getInitials(selectedRequest?.domain_id)}
                  </Avatar>
                  <div className={classes.textBox}>
                    <h3 className={classes.contentHead}>
                      {selectedRequest?.domain_id || ""}
                    </h3>
                    <p className={classes.contentbody}>
                      {moment(data[data.length - 1]?.createdAt).fromNow()}
                    </p>
                  </div>
                </div>
                {/* <IconButton>
                  <MdMoreHoriz className={classes.moreIcon} />
                </IconButton> */}
              </div>
            )
          ) : null}
          <div className={classes.bodyContainer}>
            <div className={classes.body}>
              {data?.length > 0 ? (
                <>
                  <div className={classes.messagesContainerOuter}>
                    <div
                      className={classes.messagesContainerInner}
                      id="messageInnerContainer"
                    >
                      {data.map((e, key) =>
                        e.today ? (
                          <div className={classes.line} key={key}>
                            <h2 className="hr-lines">Today</h2>
                          </div>
                        ) : (
                          <Message
                            key={key}
                            website={selectedRequest?.domain_id}
                            message={e.email_body}
                            time={e.createdAt}
                            type={e.email_type}
                          />
                        )
                      )}
                    </div>
                  </div>
                  <div className={classes.inputContainer}>
                    {/* <label htmlFor="attachment">
                      <img
                        src={AttachIcon}
                        alt="attach icon"
                        className={classes.clipIcon}
                      />
                    </label>
                    <input
                      className={classes.fileInput}
                      type="file"
                      accept="image/*"
                      id="attachment"
                      name="attachment"
                      onChange={(event) => {
                        setHelperText(
                          `Attachment: ${event.target.files[0].name}`
                        );
                      }}
                    /> */}
                    {/* <TextField
                      variant="outlined"
                      className={classes.messageInput}
                      placeholder="Type your message here..."
                      // helperText={helperText}
                      onChange={handleChangeMessage}
                      value={replyMessage}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <button
                              className={classes.sendIconButton}
                              onClick={sendReply}
                            >
                              <img src={SendIcon} alt="Send icon" referrerPolicy="no-referrer" />
                            </button>
                          </InputAdornment>
                        ),
                      }}
                    /> */}
                  </div>
                </>
              ) : (
                <div className={classes.noMessageContainer}>
                  <img
                    src={NoMessageIcon}
                    alt="No Message"
                    referrerPolicy="no-referrer"
                  />
                  {/* <h2>Inbox is empty</h2>
                  <h3> Intiate a takedown request to get started.</h3> */}
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const Message = ({ message, website, time, type }) => {
  const { userData } = useSelector((state) => state.user);
  const classes = useStyles();
  return (
    <div className={classes.message}>
      {type === "outgoing" ? (
        <Avatar className={clsx(classes.messageAvatar, classes.yellowAvatar)}>
          {userData.full_name
            ? getInitials(userData.full_name)
            : getInitials(userData.email_address)}
        </Avatar>
      ) : (
        <Avatar className={clsx(classes.messageAvatar, classes.redAvatar)}>
          {getInitials(website)}
        </Avatar>
      )}

      <div className={classes.messageContent}>
        <h3>
          {type === "outgoing"
            ? userData.full_name
              ? userData.full_name
              : userData.email_address
            : website}
          <span> {moment(time).fromNow()}</span>
        </h3>
        <div className={classes.messageBodyContainer}>
          <div className={classes.messageBody}>
            <div
              dangerouslySetInnerHTML={{
                __html: message
                  .replaceAll("<br/>&gt;", "<br />")
                  .replaceAll("<p>&gt;", "<p>"),
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DMCAMessages;
