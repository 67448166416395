import { Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import DeleteConfirmationDialog from "../../Components/Common/ConfirmationDialog.jsx";
import UpgradePlanBillingDialog from "../../Components/Common/UpgradePlanDialog.jsx";
import WebsiteDialog from "../../Components/Common/WebsiteDialog.jsx";
import DMCAPlanDialog from "../../Components/Matches/DMCAPlanDialog.jsx";
import CustomButton from "../../Components/UI/CustomButton.jsx";
import MatchesCard from "../../Components/Matches/MatchesCard.jsx";
import CustomPopup from "../../Components/UI/CustomPopup.jsx";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";
import Filter from "../../Components/UI/Filter.jsx";
import {
  addTakedownRequestAction,
  resetDMCAError,
  takedownAllAction,
  takedownMultiContent,
} from "../../Store/DMCA/actions";
import {
  clearMatchErrorAction,
  deleteSearchAction,
  getDashBoardMatches,
} from "../../Store/Matches/actions";
import MatchesTableView from "../../Components/Matches/MatchesTableView.jsx";
import { PlanNames } from "../../constants";

const useStyles = makeStyles((theme) => ({
  matchesContainer: {
    height: "100%",
  },

  bodyContainer: {
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
    marginTop: "10px !important",
  },
  upgradeDialogContainer: {
    "& .MuiPaper-root": {
      padding: "10px",
    },

    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        margin: "10px",
      },
    },
  },

  websiteDialogContainer: {
    "& .MuiPaper-root": {
      padding: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-root": {
        margin: "10px",
      },
      "& .MuiBackdrop-root": {
        backdropFilter: "blur(45px)",
      },
    },
  },
  dialogHead: {
    ...theme.typography.heading24,
    fontWeight: "700",
    margin: 0,
    textAlign: "center",
    "& span": {
      color: theme.palette.yellow,
    },
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.heading20,
    },
  },
  dialogBodyFont: {
    ...theme.typography.body16,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.body14,
    },
  },
  continueButton: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
  cancelButton: {
    fontSize: "18px !important",
    fontWeight: "400 !important",
    marginTop: "7px !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px !important",
    },
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    top: 0,
    backgroundColor: "#f0f0f0",
    padding: "10px",
    zIndex: 100,
  },
  noResultsFound: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& h2": {
      ...theme.typography.heading36,
      fontWeight: 500,
      margin: 0,
      marginBottom: "20px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
  },
}));

const Matches = () => {
  const classes = useStyles();
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [websiteModal, setwebsiteModal] = React.useState(false);
  // const [upgradePlan, setUpgradePlan] = React.useState(false);
  const [takeDownModal, setTakeDownModal] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState();
  const [isTableView, setIsTableView] = React.useState(false);
  const [showUpgradePlanBillingDialog, setShowUpgradePlanBillingDialog] =
    React.useState(false);
  const [filterOptions, setFilterOptions] = React.useState({
    image: false,
    video: false,
    websites: [],
  });
  const [takedownConfirmationPopup, setTakedownConfirmationPopup] =
    React.useState(false);
  const [selectedTakedownConfirm, setSelectedTakedownConfirm] =
    React.useState(false);

  const { matches, loading } = useSelector((state) => state.matches);
  const { userData } = useSelector((state) => state.user);
  const [isSelecting, setIsSelecting] = React.useState(false);
  const [selectedIds, setSelectedIds] = React.useState([]);

  const {
    dmca,
    isSubscriptionActive,
    error,
    errorMessage,
    errorTitle,
    loading: DmcaLoading,
  } = useSelector((state) => state.DMCA);
  const userRedux = useSelector((State) => State?.user);

  const subscriptionsRedux = useSelector((State) => State?.Subscriptions);

  const showForm = () => {
    if (
      !userData?.country ||
      !userData?.city ||
      !userData?.street ||
      !userData?.zip_code ||
      !userData?.state ||
      !userData?.full_name
    ) {
      return true;
    }
    return false;
  };

  const getMatches = useMemo(
    () =>
      matches
        .filter((e) => {
          if (filterOptions.image && filterOptions.video) {
            return true;
          }
          if (filterOptions.image) {
            return e.source_type === "image";
          }
          if (filterOptions.video) {
            return e.source_type === "video";
          }
          return true;
        })
        .filter((e) => {
          if (filterOptions.websites.length === 0) {
            return true;
          }
          return filterOptions.websites.includes(e.domain);
        }),
    [matches, filterOptions]
  );

  const renderswitchUi = () => (
    <div className={classes.buttonsContainer}>
      <div className={classes.iconsContainer}>
        <FormatListBulletedIcon
          sx={{
            padding: "5px",
            cursor: "pointer",
            backgroundColor: isTableView ? "#408266" : "",
            color: isTableView ? "white" : "black",
          }}
          onClick={() => {
            setIsTableView(true);
          }}
        />
        <GridViewIcon
          sx={{
            padding: "5px",
            cursor: "pointer",
            backgroundColor: !isTableView ? "#408266" : "",
            color: !isTableView ? "white" : "black",
          }}
          onClick={() => {
            setIsTableView(false);
          }}
        />
      </div>
      <div style={{ display: "flex" }}>
        {!isTableView && (
          <div>
            {isSelecting && selectedIds?.length > 0 && (
              <>
                {/* <Button
                  variant="contained"
                  className={classes.button}
                  style={{ backgroundColor: "#408266" }}
                >
                  Archive {selectedIds.length ?? 0}
                </Button> */}
                {/* <Button
                  variant="contained"
                  className={classes.button}
                  style={{ backgroundColor: "#408266" }}
                  // onClick={() => onBulkSave(selectedIds)}
                >
                  TakeDown {selectedIds.length ?? 0}
                </Button> */}
              </>
            )}
            {/* {isSelecting && (
              <Button
                variant="contained"
                className={classes.button}
                style={{ backgroundColor: "#408266", marginLeft: "5px" }}
                onClick={() =>
                  setIsSelecting((prevState) => {
                    if (prevState) {
                      setSelectedIds([]);
                    }
                    return !prevState;
                  })
                }
              >
                Cancel
              </Button>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
  const openWebsiteModal = (website) => {
    setwebsiteModal(website);
  };

  const closeWebsiteModal = () => {
    setwebsiteModal(false);
  };

  const closeTakedownModal = () => {
    setTakeDownModal(false);
  };

  const openTakeDownModal = () => {
    setTakeDownModal(true);
  };

  const closeUpgradePlanDialog = () => {
    dispatch(resetDMCAError());
  };

  const closeDeleteModal = () => {
    setDeleteModal(false);
  };

  const openDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleTakeDown = (e) => {
    setSelectedImage(e);
    openTakeDownModal();
  };

  const handleTakeDownConfirmation = () => {
    sessionStorage.setItem(
      "takedown",
      JSON.stringify({
        asset_id: selectedImage.asset_id,
        match_id: selectedImage._id,
        face: selectedImage.face,
        source_id: selectedImage.source_id,
        domain: selectedImage.domain,
        asset_url:
          selectedImage.source_type === "image"
            ? selectedImage.faceSignedUrl
            : selectedImage.frameSignedUrl,
        page_url: selectedImage.page_url,
      })
    );
    dispatch(
      addTakedownRequestAction(
        {
          asset_id: selectedImage.asset_id,
          match_id: selectedImage._id,
          face: selectedImage.face,
          source_id: selectedImage.source_id,
          domain: selectedImage.domain,
          asset_url:
            selectedImage.source_type === "image"
              ? selectedImage.faceSignedUrl
              : selectedImage.frameSignedUrl,
          page_url: selectedImage.page_url,
        },
        () => closeTakedownModal(),
        () => closeTakedownModal()
      )
    );
  };

  const handleDelete = (data) => {
    openDeleteModal();

    setSelectedImage(data);
  };

  const handleDeleteConfirmation = () => {
    dispatch(
      deleteSearchAction({
        face: selectedImage.face,
        source_id: selectedImage.source_id,
      })
    );
    closeDeleteModal();
  };
  const onTakeDown = (e) => {
    handleTakeDown(e);
  };
  const addPaymentSession = () => {
    const fscSession = {
      reset: true,
      products: [
        {
          path: "dmca-4",
          quantity: 1,
        },
      ],
      paymentContact: {
        email: userData.email_address,
        firstName: userData?.full_name?.split(" ")[0],
        lastName: userData?.full_name?.split(" ")[1],
        addressLine1: userData.street,
        addressLine2: userData.suite,
        city: userData.city,
        region: userData.state,
        country: userData.country,
        postalCode: userData.zip_code,
      },
    };

    window.fastspring.builder.push(fscSession);
    window.fastspring.builder.checkout();
  };

  const handleAddDMCAPayment = () => {
    addPaymentSession();
    closeUpgradePlanDialog();
  };

  useEffect(() => {
    dispatch(getDashBoardMatches());
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(clearMatchErrorAction());
      setFilterOptions({ websites: [], video: false, image: false });
    },
    [dispatch]
  );

  const handleSelect = (id) => {
    setSelectedIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((prevId) => prevId !== id);
      }
      return [...prevIds, id];
    });
  };

  useEffect(() => {
    if (selectedIds.length === 0 && isSelecting) {
      setIsSelecting(false);
    }
  }, [selectedIds]);
  return (
    <div className={classes.matchesContainer}>
      {loading || DmcaLoading ? (
        <div className={classes.loaderContainer}>
          <CustomSpinner />
        </div>
      ) : matches.length > 0 ? (
        <>
          <WebsiteDialog open={websiteModal} onClose={closeWebsiteModal} />
          <DeleteConfirmationDialog
            open={takedownConfirmationPopup}
            heading="Takedown all content"
            body="Are you sure want to takedown All of these content?"
            confirmButtonName="Yes"
            cancelButtonName="No"
            onClose={() => {
              setTakedownConfirmationPopup(false);
            }}
            handleConfirmation={() => {
              setTakedownConfirmationPopup(false);
              dispatch(
                takedownAllAction(() => {
                  navigate("/takedown-requests");
                })
              );
            }}
          />

          <DeleteConfirmationDialog
            open={selectedTakedownConfirm}
            heading="Takedown Selected Content"
            body="Are you sure want to takedown all of these selected content?"
            confirmButtonName="Yes"
            cancelButtonName="No"
            onClose={() => {
              setSelectedTakedownConfirm(false);
            }}
            handleConfirmation={() => {
              setSelectedTakedownConfirm(false);
              const selectedMatches = getMatches.filter((match) =>
                selectedIds.includes(match._id)
              );
              dispatch(
                takedownMultiContent(selectedMatches, () => {
                  navigate("/takedown-requests");
                })
              );
            }}
          />

          <UpgradePlanDialog
            open={error && !isSubscriptionActive}
            heading={errorTitle}
            body={errorMessage}
            confirmText={errorTitle}
            cancelText="Cancel"
            continueButton={() => {
              setShowUpgradePlanBillingDialog(true);
              dispatch(resetDMCAError());
            }}
            onClose={closeUpgradePlanDialog}
          />

          <TakeDownConfirmationDialog
            open={takeDownModal}
            onClose={closeTakedownModal}
            handleTakeDownConfirmation={handleTakeDownConfirmation}
          />

          <UpgradePlanBillingDialog
            open={showUpgradePlanBillingDialog}
            onClose={() => setShowUpgradePlanBillingDialog(false)}
            reason="savedMatches"
          />
          <DeleteConfirmationDialog
            open={deleteModal}
            onClose={closeDeleteModal}
            handleConfirmation={handleDeleteConfirmation}
            heading="Are you sure this isn't you ?"
            body="We will not show you this result again."
            confirmButtonName="Yes"
            cancelButtonName="No"
          />
          <DMCAPlanDialog
            open={error && !dmca && isSubscriptionActive}
            onClose={closeUpgradePlanDialog}
            handleClick={handleAddDMCAPayment}
          />
          {/* <Filter
            setFilterOptions={setFilterOptions}
            websites={matches
              .map((item) => item.domain)
              .filter((value, index, self) => self.indexOf(value) === index)}
          /> */}

          <div className={`${classes.buttonsContainer}`}>
            {renderswitchUi()}
            <div style={{ display: "flex", alignItems: "center" }}>
              {" "}
              {(userRedux?.userData?.agency_id ||
                (subscriptionsRedux?.activeSubscription &&
                  PlanNames[subscriptionsRedux?.activeSubscription] ===
                    "loti creator")) && (
                <div>
                  {isSelecting && selectedIds?.length > 0 && !isTableView && (
                    <Button
                      variant="contained"
                      className={classes.button}
                      style={{ backgroundColor: "#408266" }}
                      onClick={() => setSelectedTakedownConfirm(true)}
                    >
                      Takedown {selectedIds.length ?? 0}
                    </Button>
                  )}
                  {!isSelecting && !isTableView && (
                    <Button
                      variant="contained"
                      className={classes.button}
                      style={{ backgroundColor: "#408266" }}
                      onClick={() => !showForm()?setTakedownConfirmationPopup(true):navigate('/takedown-requests')}
                    >
                      Takedown All
                    </Button>
                  )}

                  {isSelecting && !isTableView && (
                    <Button
                      variant="contained"
                      className={classes.button}
                      style={{ backgroundColor: "#408266", marginLeft: "5px" }}
                      onClick={() =>
                        setIsSelecting((prevState) => {
                          if (prevState) {
                            setSelectedIds([]);
                          }
                          return !prevState;
                        })
                      }
                    >
                      Cancel
                    </Button>
                  )}
                </div>
              )}
              <Filter
                setFilterOptions={setFilterOptions}
                websites={matches
                  .map((item) => item.domain)
                  .filter(
                    (value, index, self) => self.indexOf(value) === index
                  )}
              />
            </div>
          </div>

          {isTableView ? (
            <MatchesTableView
              data={getMatches}
              buttonActions={{
                onTakeDown,
                takeDownallFunc: () => setTakedownConfirmationPopup(true),
                selectedTakedownFunc: () => setSelectedTakedownConfirm(true),
              }}
              setSelected={setSelectedIds}
              selected={selectedIds}
              handleDelete={handleDelete}
              subscriptionsRedux={subscriptionsRedux}
            />
          ) : (
            <Grid container spacing={2} className={classes.bodyContainer}>
              {getMatches.map((e, key) => (
                <Grid item xs={12} sm={4} md={3} lg={3} key={key}>
                  <MatchesCard
                    image={ e.image_url ?? e.frame_url }
                    faceSignedUrl={e.faceSignedUrl}
                    website={e.domain}
                    sourceType={e.source_type}
                    distance={e.distance}
                    takedownId={e.takedown_id}
                    takdownStatus={e.takedown_status}
                    openModal={() => openWebsiteModal(e)}
                    onTakeDown={() => handleTakeDown(e)}
                    handleDelete={() => handleDelete(e)}
                    isSelecting={isSelecting}
                    setIsSelecting={() => setIsSelecting(!isSelecting)}
                    onSelect={() => handleSelect(e._id)} // Call handleSelect function with the ID as the argument
                    isSelected={selectedIds.includes(e._id)} // Check if the ID is in the selectedIds list
                    subscriptionsRedux={subscriptionsRedux}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </>
      ) : (
        <div className={classes.noResultsFound}>
          <h2>No match available for this user</h2>
        </div>
      )}
    </div>
  );
};

const UpgradePlanDialog = ({
  open,
  onClose,
  heading,
  body,
  confirmText,
  cancelText,
  continueButton,
}) => {
  const classes = useStyles();

  return (
    <CustomPopup
      open={Boolean(open)}
      onClose={onClose}
      className={classes.upgradeDialogContainer}
    >
      <h2 className={classes.dialogHead}>{heading}</h2>
      <p className={classes.dialogBodyFont}>{body}</p>
      <CustomButton
        yellow
        className={classes.continueButton}
        onClick={continueButton}
      >
        {confirmText}
      </CustomButton>
      <CustomButton className={classes.cancelButton} onClick={onClose}>
        {cancelText}
      </CustomButton>
    </CustomPopup>
  );
};

const TakeDownConfirmationDialog = ({
  open,
  onClose,
  handleTakeDownConfirmation,
}) => {
  const classes = useStyles();
  const { loading } = useSelector((state) => state.DMCA);

  return (
    <CustomPopup
      open={Boolean(open)}
      onClose={onClose}
      className={classes.upgradeDialogContainer}
    >
      {loading ? (
        <CustomSpinner />
      ) : (
        <>
          <h2 className={classes.dialogHead}>
            Are you sure want to start take down process for this asset.
          </h2>
          <br />
          <CustomButton
            yellow
            className={classes.continueButton}
            onClick={handleTakeDownConfirmation}
          >
            Yes
          </CustomButton>
          <CustomButton className={classes.cancelButton} onClick={onClose}>
            No
          </CustomButton>
        </>
      )}
    </CustomPopup>
  );
};
export default Matches;
