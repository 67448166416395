import { Grid, Button } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";

import WebsiteDialog from "../../Components/Common/WebsiteDialog.jsx";
import SearchCard from "../../Components/Searches/SearchCard.jsx";
import CustomSpinner from "../../Components/UI/CustomSpinner.jsx";
import Filter from "../../Components/UI/Filter.jsx";
import TableView from "../TableView/TableView.jsx";
import {
  clearMatchErrorAction,
  deleteSearchAction,
  getDashBoardSearch,
  saveMatchAction,
  bulkSaveMatchAction,
  bulkArchiveSearchAction,
} from "../../Store/Matches/actions";
import ArchiveConfirmationDialog from "../../Components/Common/ConfirmationDialog.jsx";

const useStyles = makeStyles((theme) => ({
  searchContainer: {
    height: "100%",
    width: "100%",
  },
  dropdownContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(1),
  },
  buttonsContainer: {
    display: "flex",
    alignItems: "center" /* Align items vertically in the center */,
    justifyContent:
      "space-between" /* Distribute items along the main axis with space between them */,
    top: 0,
    backgroundColor: "#f0f0f0",
    padding: "10px",
    zIndex: 100,
    marginBottom: "10px",
  },
  select: {
    width: "120px",
    fontSize: "14px",
    fontWeight: "bold",
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  menuItem: {
    fontSize: "14px",
  },
  bodyContainer: {
    paddingBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  bodyBottom: {
    paddingBottom: "50px",
    marginTop: "4rem",
  },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  additionalImagesContainer: {
    marginTop: "25px",
  },
  head: {
    ...theme.typography.heading32,
    fontWeight: 500,
    margin: 0,
    marginLeft: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      marginLeft: "15px",
      marginRight: "10px",
    },
  },
  para: {
    ...theme.typography.body20,
    margin: 0,
    marginBottom: "20px",
    marginLeft: "10px",

    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
      marginLeft: "15px",
      marginRight: "10px",
    },
  },
  divider: {
    width: "100%",
    marginTop: "5px",
    borderBottom: `1px solid ${theme.palette.grey5}`,
  },

  noSearchContianer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& h2": {
      ...theme.typography.heading36,
      fontWeight: 500,
      margin: 0,
      marginBottom: "20px",
      textAlign: "center",
      [theme.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
    },
  },
}));

const Search = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const classes = useStyles();
  const { searches, loading, error, errorMessage, bulkSaveLoading } =
    useSelector((State) => State.matches);

  const [websiteModal, setwebsiteModal] = React.useState(false);
  const [isTableView, setIsTableView] = React.useState(false);
  const [filterOptions, setFilterOptions] = React.useState({
    image: false,
    video: false,
    archived: false,
    websites: [],
  });
  const [isSelecting, setIsSelecting] = React.useState(false);
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [archiveConfirmationPopup, setArchiveConfirmationPopup] =
    React.useState(false);
  const [archivedTableId, setArchivedTableId] = React.useState();
  React.useEffect(() => {
    setSelectedIds([]);
  }, [filterOptions]);
  const fromOnboarding = search?.split("?")[1]?.split("=")[0] === "onboarding";

  const getSearches = () => {
    if (fromOnboarding) {
      return JSON.parse(sessionStorage.getItem("searches")) || [];
    }
    return searches;
  };
  const bulkMatchesSuccessCallback = () => {
    dispatch(getDashBoardSearch());
    setSelectedIds([]);
    setIsSelecting(false);
  };

  const filteredSearches = useMemo(
    () =>
      getSearches()
        ?.filter((e) => {
          if (filterOptions.image && filterOptions.video) {
            return true;
          }
          if (filterOptions.image) {
            return e.source_type === "image";
          }
          if (filterOptions.video) {
            return e.source_type === "video";
          }

          return true;
        })
        .filter((e) => {
          if (filterOptions.archived) {
            return e.archived;
          }
          return !e.archived;
        })
        .filter((e) => {
          if (filterOptions.websites.length === 0) {
            return true;
          }
          return filterOptions.websites.includes(e.domain);
        }),
    [getSearches, filterOptions]
  );
  const openWebsiteModal = (e) => {
    setwebsiteModal(e);
  };

  const closeWebsiteModal = () => {
    setwebsiteModal(false);
  };

  const saveAsset = (e) => {
    dispatch(
      saveMatchAction(
        {
          id: e.id,
          // asset_id: e.asset_id,
          // source_id: e.source_id,
          // face: e.face,
          // frame: e.image,
          // domain: e.domain,
          // image_url: e.image_url,
          // video_url: e.video_url,
          // page_url: e.page_url,
          // distance: e.distance,
          // source_type: e.source_type,
          // title: e.title,
          // search_id: e.search_id,
        },
        fromOnboarding
      )
    );
  };

  const deleteSearch = (e) =>
    dispatch(
      deleteSearchAction(
        { source_id: e.source_id, face: e.face },
        fromOnboarding
      )
    );
  const getSelectedData = (ids) =>
    searches.filter((s, index) => ids.includes(index));

  const getTableData = (data) => {
    if (data) {
      return data.map((d, index) => ({
        id: index,
        Image: d.source_type === "image" ? d.image_url : d.frameSignedUrl,
        Url: d.source_type === "image" ? d.image_url : d.video_url,
        MediaType: d.source_type,
        Domain: d.domain,
        Confidence: Math.round(d.distance),
        ...d,
      }));
    }
    return data;
  };

  const onSaveMatches = (ids) => {
    const selectedSearches = getSelectedData(ids);
    const payload = selectedSearches.map((s) => ({
      asset_id: s.asset_id,
      source_id: s.source_id,
      face: s.face,
      frame: s.image,
      domain: s.domain,
      image_url: s.image_url,
      video_url: s.video_url,
      page_url: s.page_url,
      distance: s.distance,
      source_type: s.source_type,
      title: s.title,
      search_id: s.search_id,
    }));

    dispatch(bulkSaveMatchAction(payload, bulkMatchesSuccessCallback));
    // window.location.reload();
  };

  const onBulkSave = (ids) => {
    const newIds = ids.map((id) => {
      const newId = id.slice(7, id.length);
      return Number(newId);
    });
    const selectedSearches = getSelectedData(newIds);
    const payload = selectedSearches.map((s) => ({
      asset_id: s.asset_id,
      source_id: s.source_id,
      face: s.face,
      frame: s.image,
      domain: s.domain,
      image_url: s.image_url,
      video_url: s.video_url,
      page_url: s.page_url,
      distance: s.distance,
      source_type: s.source_type,
      title: s.title,
      search_id: s.search_id,
    }));
    dispatch(bulkSaveMatchAction(payload, bulkMatchesSuccessCallback));
  };

  const onArchive = (ids) => {
    const newIds = isTableView
      ? archivedTableId.map((id) => {
          const newId = id.slice(7, id.length);
          return Number(newId);
        })
      : ids.map((id) => {
          const newId = id.slice(7, id.length);
          return Number(newId);
        });
    const selectedSearches = getSelectedData(newIds);
    const payload = selectedSearches.map((s) => ({
      source_id: s.source_id,
      face: s.face,
    }));

    dispatch(bulkArchiveSearchAction(payload, bulkMatchesSuccessCallback));
    setArchiveConfirmationPopup(false);
  };

  useEffect(() => {
    dispatch(getDashBoardSearch());

    return () => {
      dispatch(clearMatchErrorAction());
    };
  }, [dispatch]);

  useEffect(() => {
    if (selectedIds.length === 0 && isSelecting) {
      setIsSelecting(false);
    }
  }, [selectedIds]);
  const renderswitchUi = () => (
    <div className={classes.buttonsContainer}>
      <div className={classes.iconsContainer}>
        <FormatListBulletedIcon
          sx={{
            padding: "5px",
            cursor: "pointer",
            backgroundColor: isTableView ? "#408266" : "",
            color: isTableView ? "white" : "black",
          }}
          onClick={() => {
            setIsTableView(true);
          }}
        />
        <GridViewIcon
          sx={{
            padding: "5px",
            cursor: "pointer",
            backgroundColor: !isTableView ? "#408266" : "",
            color: !isTableView ? "white" : "black",
          }}
          onClick={() => {
            setIsTableView(false);
          }}
        />
      </div>
      <div style={{ display: "flex" }}>
        {!isTableView && (
          <div>
            {isSelecting && selectedIds?.length > 0 && (
              <>
                {/* <Button
                  variant="contained"
                  className={classes.button}
                  style={{ backgroundColor: "#408266" }}
                >
                  Archive {selectedIds.length ?? 0}
                </Button> */}
                <Button
                  variant="contained"
                  className={classes.button}
                  style={{ backgroundColor: "#408266" }}
                  onClick={() => onBulkSave(selectedIds)}
                >
                  Save ( {selectedIds.length ?? 0} )
                </Button>
                {!filteredSearches.find((it) => it.archived) && (
                  <Button
                    variant="contained"
                    className={classes.button}
                    style={{ backgroundColor: "#408266", marginLeft: "5px" }}
                    onClick={() => setArchiveConfirmationPopup(true)}
                  >
                    Archive ( {selectedIds.length ?? 0} )
                  </Button>
                )}
              </>
            )}
            {isSelecting && (
              <Button
                variant="contained"
                className={classes.button}
                style={{ backgroundColor: "#408266", marginLeft: "5px" }}
                onClick={() =>
                  setIsSelecting((prevState) => {
                    if (prevState) {
                      setSelectedIds([]);
                    }
                    return !prevState;
                  })
                }
              >
                Cancel
              </Button>
            )}
          </div>
        )}
        <Filter
          setFilterOptions={setFilterOptions}
          websites={getSearches()
            ?.map((item) => item.domain)
            .filter((value, index, self) => self.indexOf(value) === index)}
        />
      </div>
    </div>
  );
  const handleSelect = (id) => {
    setSelectedIds((prevIds) => {
      if (prevIds.includes(id)) {
        return prevIds.filter((prevId) => prevId !== id);
      }
      return [...prevIds, id];
    });
  };

  const onOpenArchivePopup = (data) => {
    setArchiveConfirmationPopup(true);
    setArchivedTableId(data);
  };

  return (
    <>
      <ArchiveConfirmationDialog
        open={archiveConfirmationPopup}
        heading="Archive All Selected Content"
        body="Are you sure want to archive all of these selected content?"
        confirmButtonName="Yes"
        cancelButtonName="No"
        onClose={() => {
          setArchiveConfirmationPopup(false);
        }}
        handleConfirmation={() => {
          onArchive(selectedIds);
        }}
      />
      {isTableView ? (
        <div className={classes.searchContainer}>
          {renderswitchUi()}
          {searches?.length === 0 || bulkSaveLoading ? (
            <div className={classes.loaderContainer}>
              <CustomSpinner />
            </div>
          ) : (
            <>
              <TableView
                // data={getTableData(searches)}
                data={getTableData(
                  filteredSearches?.filter((e) => e.distance <= 400)
                )}
                buttonActions={{ onSaveMatches, onOpenArchivePopup }}
                onSave={saveAsset}
                handleNotMe={deleteSearch}
              />

              <div className={classes.bodyBottom}>
                {filteredSearches?.filter((e) => e.distance > 250).length >
                  0 && (
                  <>
                    <div className={classes.divider} />
                    <div className={classes.additionalImagesContainer}>
                      <h2 className={classes.head}>Additional Results</h2>
                      <p className={classes.para}>
                        These images look a lot like you but we are less
                        confident that it is you. We’re showing you in case
                        these outliers do in fact have your face; it’s important
                        to us that you’re entirely covered.
                      </p>

                      <TableView
                        // data={getTableData(searches)}
                        data={getTableData(
                          filteredSearches?.filter((e) => e.distance > 400)
                        )}
                        buttonActions={{ onSaveMatches, onOpenArchivePopup }}
                        onSave={saveAsset}
                        handleNotMe={deleteSearch}
                      />
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className={classes.searchContainer}>
          {renderswitchUi()}

          <WebsiteDialog open={websiteModal} onClose={closeWebsiteModal} />

          {error ? (
            <div className={classes.noSearchContianer}>
              <h2>{errorMessage}</h2>
            </div>
          ) : (
            <>
              {loading ? (
                <div className={classes.loaderContainer}>
                  <CustomSpinner />
                </div>
              ) : getSearches()?.length > 0 ? (
                <>
                  {/* initially Filter was here  */}

                  <h2 className={classes.head}>Your Results</h2>

                  <p className={classes.para}>
                    We found{"  "}
                    {
                      filteredSearches?.filter((e) => e.distance <= 400).length
                    }{" "}
                    {"  "}
                    results for you
                  </p>
                  {/* <Grid item md={6} lg={6} />
                {(userRedux?.userData?.agency_id ||
                  (subscriptionsRedux?.activeSubscription &&
                    PlanNames[subscriptionsRedux?.activeSubscription] ===
                      "loti creator")) && (
                  <Grid item md={2} lg={2} sx={{ textAlign: "right" }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#408266",
                        color: "#ffffff",
                        padding: 2,
                      }}
                      onClick={() => setTakedownConfirmationPopup(true)}
                    >
                      Takedown All
                    </Button>
                  </Grid>
                )}
              </Grid> */}

                  <Grid container spacing={2} className={classes.bodyContainer}>
                    {filteredSearches
                      ?.filter((e) => e.distance <= 400)
                      ?.map((e, key) => (
                        <Grid item xs={12} sm={4} md={3} lg={3} key={key}>
                          <SearchCard
                            image={e.image_url ?? e.frame_url
                            }
                            faceSignedUrl={e.faceSignedUrl}
                            sourceType={e.source_type}
                            website={e.domain}
                            distance={e.distance}
                            onSave={() => saveAsset(e)}
                            openModal={() => openWebsiteModal(e)}
                            handleNotMe={() => deleteSearch(e)}
                            isSelecting={isSelecting}
                            setIsSelecting={() => setIsSelecting(!isSelecting)}
                            onSelect={() => handleSelect(e.id)} // Call handleSelect function with the ID as the argument
                            isSelected={selectedIds.includes(e.id)} // Check if the ID is in the selectedIds list
                          />
                        </Grid>
                      ))}
                  </Grid>
                  <div className={classes.bodyBottom}>
                    {filteredSearches?.filter((e) => e.distance > 250).length >
                      0 && (
                      <>
                        <div className={classes.divider} />
                        <div className={classes.additionalImagesContainer}>
                          <h2 className={classes.head}>Additional Results</h2>
                          <p className={classes.para}>
                            These images look a lot like you but we are less
                            confident that it is you. We’re showing you in case
                            these outliers do in fact have your face; it’s
                            important to us that you’re entirely covered.
                          </p>
                          <Grid
                            container
                            spacing={2}
                            className={classes.bodyContainer}
                          >
                            {filteredSearches
                              ?.filter((e) => e.distance > 400)
                              ?.map((e, key) => (
                                <Grid
                                  item
                                  xs={12}
                                  sm={4}
                                  md={3}
                                  lg={3}
                                  key={key}
                                >
                                  <SearchCard
                                    image={
                                      e.source_type === "image"
                                        ? e.image_url
                                        : e.frameSignedUrl
                                    }
                                    faceSignedUrl={e.faceSignedUrl}
                                    sourceType={e.source_type}
                                    website={e.domain}
                                    distance={e.distance}
                                    onSave={() => saveAsset(e)}
                                    openModal={() => openWebsiteModal(e)}
                                    handleNotMe={() => deleteSearch(e)}
                                    isSelecting={isSelecting}
                                    setIsSelecting={() =>
                                      setIsSelecting(!isSelecting)
                                    }
                                    onSelect={() => handleSelect(e.id)} // Call handleSelect function with the ID as the argument
                                    isSelected={selectedIds.includes(e.id)} // Check if the ID is in the selectedIds list
                                  />
                                </Grid>
                              ))}
                          </Grid>
                        </div>
                      </>
                    )}
                  </div>
                </>
              ) : (
                <div className={classes.noSearchContianer}>
                  <h2>
                    We didn’t find any result for your asset. Try another asset
                    or keep searching periodically. We scan millions of websites
                    every week
                  </h2>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Search;
