import { put, all, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";
import { GET_DASHBOARD_MATCHES } from "../Matches/actionTypes";
import * as actionTypes from "./actionTypes";
import API from "../../Axios/Axios";

function* getRequestsSaga() {
  try {
    yield put({ type: actionTypes.SET_DMCA_LOADING });
    const { data } = yield API.get("/dmca/requests");
    if (data.success) {
      yield put({
        type: actionTypes.GET_REQUESTS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_REQUESTS_FAILURE,
        payload: "Unable to fetch requests",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_REQUESTS_FAILURE,
      payload: error?.response?.data?.message || "Unable to fetch requests",
    });
  }
}

function* getEmailsByRequestSaga({ reqID }) {
  try {
    yield put({ type: actionTypes.SET_EMAIL_LOADING });
    const { data } = yield API.get(`/dmca/emails/${reqID}`);
    if (data.success) {
      yield put({
        type: actionTypes.GET_EMAILS_BY_REQUEST_SUCCESS,
        payload: data.data.Emails,
      });
    } else {
      yield put({
        type: actionTypes.GET_EMAILS_BY_REQUEST_FAILURE,
        payload: "Unable to fetch email for the request",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_EMAILS_BY_REQUEST_FAILURE,
      payload:
        error?.response?.data?.message ||
        "Unable to fetch email for the request",
    });
  }
}

function* addTakedownRequestSaga({ payload, successCallback, failCallback }) {
  try {
    yield put({ type: actionTypes.SET_DMCA_LOADING });
    const { data } = yield API.post("/dmca/takedown", payload);
    if (data.success) {
      yield put({
        type: actionTypes.ADD_TAKEDOWN_REQUEST_SUCCESS,
      });
      toast.success(
        "We have created take down request. Please visit Takedown requests section to process it."
      );
      yield put({
        type: GET_DASHBOARD_MATCHES,
      });
      if (successCallback) successCallback();
    } else {
      yield put({
        type: actionTypes.ADD_TAKEDOWN_REQUEST_FAILURE,
        payload: "Unable to make request.",
      });
      if (failCallback) failCallback();
    }
  } catch (error) {
    yield put({
      type: actionTypes.ADD_TAKEDOWN_REQUEST_FAILURE,
      payload: error?.response?.data?.message?.message
        ? error?.response?.data?.message?.message
        : error?.response?.data?.message || "Unable to make request.",
      title: error?.response?.data?.message?.title || "",
      subscribed: error?.response?.data?.message?.subscribed || false,
      dmca: error?.response?.data?.message?.dmca || false,
    });

    if (failCallback) failCallback();
  }
}

function* sendReplySaga({ payload }) {
  try {
    yield put({ type: actionTypes.SET_EMAIL_LOADING });
    const { data } = yield API.post("/dmca/reply", payload);
    if (data.success) {
      yield put({
        type: actionTypes.SEND_REPLY_SUCCESS,
      });
      yield put({
        type: actionTypes.GET_EMAILS_BY_REQUEST,
        reqID: payload.takedown_id,
      });
    } else {
      yield put({
        type: actionTypes.SEND_REPLY_FAILURE,
        payload: "Unable to send reply",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.SEND_REPLY_FAILURE,
      payload: error?.response?.data?.message || "Unable to send reply.",
    });
  }
}

function* getAddedAssetTakedDownRequestSaga({payload}) {
  try {
    yield put({ type: actionTypes.SET_DMCA_LOADING });
    const { data } = yield API.get("/dmca/requested-matches",{params:payload});
    if (data.success) {
      yield put({
        type: actionTypes.GET_ADDED_ASSET_TAKEDOWN_REQUEST_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.GET_ADDED_ASSET_TAKEDOWN_REQUEST_FAILURE,
        payload: "Unable to fetch requests",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.GET_ADDED_ASSET_TAKEDOWN_REQUEST_FAILURE,
      payload: error?.response?.data?.message?.message
        ? error?.response?.data?.message?.message
        : error?.response?.data?.message || "Unable to fetch requests",
      title: error?.response?.data?.message?.title,
      isSubscriptionActive: error?.response?.data?.message?.subscribed,
    });
  }
}

function* startTakedownProcessSaga({ payload }) {
  try {
    yield put({ type: actionTypes.START_LOADING_RESP });
    const { data } = yield API.post("/dmca/start-takedown-process", payload);
    if (data.success) {
      yield put({
        type: actionTypes.START_TAKEDOWN_PROCESS_SUCCESS,
        payload: data.data,
      });
    } else {
      yield put({
        type: actionTypes.START_TAKEDOWN_PROCESS_FAILURE,
        payload: "Unable to start process",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.START_TAKEDOWN_PROCESS_FAILURE,
      payload: error?.response?.data?.message || "Unable to start process",
    });
  }
}

function* addDmcaOrderSaga({ payload, successCallback }) {
  try {
    yield put({ type: actionTypes.SET_DMCA_LOADING });
    const { data } = yield API.post("/dmca/payment", payload);
    if (data.success) {
      yield put({
        type: actionTypes.ADD_DMCA_ORDER_DETAILS_SUCCESS,
      });
      if (successCallback) {
        successCallback();
      }
      toast.success("DMCA payment successfull");
    } else {
      yield put({
        type: actionTypes.ADD_DMCA_ORDER_DETAILS_FAILURE,
        payload: "Unable to start process",
      });
    }
  } catch (error) {
    yield put({
      type: actionTypes.ADD_DMCA_ORDER_DETAILS_FAILURE,
      payload: error?.response?.data?.message || "Unable to start process",
    });
  }
}

function* takedownAll({ successCallback }) {
  try {
    yield put({ type: actionTypes.TAKEDOWN_ALL_LOADING });
    const { data } = yield API.post("/dmca/takedown-all");
    if (data.success) {
      yield put({
        type: actionTypes.TAKEDOWN_ALL_SUCCESS,
      });
      if (successCallback) {
        successCallback();
      }
      toast.success(data.data);
    } else {
      yield put({
        type: actionTypes.TAKEDOWN_ALL_FAILURE,
        payload: "Unable to takedown",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.TAKEDOWN_ALL_FAILURE,
      payload: error?.response?.data?.message || "Unable to takedown",
    });
    toast.error(error?.response?.data?.message || "Unable to process takedown request");
  }
}


function* takedownMultiContent({ payload, successCallback }) {
  try {
    yield put({ type: actionTypes.TAKEDOWN_MULTI_CONTENT_LOADING });
    const { data } = yield API.post("/dmca/bulk-takedown", payload);
    if (data.success) {
      yield put({
        type: actionTypes.TAKEDOWN_MULTI_CONTENT_SUCCESS,
      });
      if (successCallback) {
        successCallback();
      }
      toast.success(data.data);
    } else {
      yield put({
        type: actionTypes.TAKEDOWN_MULTI_CONTENT_FAILURE,
        payload: "Unable to takedown",
      });
      toast.error(data.message);
    }
  } catch (error) {
    yield put({
      type: actionTypes.TAKEDOWN_MULTI_CONTENT_FAILURE,
      payload: error?.response?.data?.message || "Unable to takedown",
    });
    toast.error(error?.response?.data?.message || "Unable to process takedown request");
  }
}

function* DMCASaga() {
  yield all([
    yield takeLatest(actionTypes.GET_REQUESTS, getRequestsSaga),
    yield takeLatest(actionTypes.GET_EMAILS_BY_REQUEST, getEmailsByRequestSaga),
    yield takeLatest(actionTypes.ADD_TAKEDOWN_REQUEST, addTakedownRequestSaga),
    yield takeLatest(actionTypes.SEND_REPLY, sendReplySaga),
    yield takeLatest(
      actionTypes.GET_ADDED_ASSET_TAKEDOWN_REQUEST,
      getAddedAssetTakedDownRequestSaga
    ),
    yield takeLatest(
      actionTypes.START_TAKEDOWN_PROCESS,
      startTakedownProcessSaga
    ),
    yield takeLatest(actionTypes.ADD_DMCA_ORDER_DETAILS, addDmcaOrderSaga),
    yield takeLatest(actionTypes.TAKEDOWN_ALL, takedownAll),
    yield takeLatest(actionTypes.TAKEDOWN_MULTI_CONTENT, takedownMultiContent),
  ]);
}

export default DMCASaga;
