import * as actionTypes from "./actionTypes";

const initialState = {
  error: null,
  loading: false,
  emailLoading: false,
  errorTitle: "",
  isSubscriptionActive: false,
  dmcaActive: false,
  loadingResp: false,
  errorMessage: "",
  requests: [],
  emails: [],
  addedRequests: [],
  pageNumber:undefined,
  perPage:undefined,
  totalPages:undefined,
  totalRecords:undefined,
  dataLength:undefined
};

const DMCA = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_DMCA_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
        errorTitle: "",
        isSubscriptionActive: "",
        errorMessage: "",
      };
    case actionTypes.SET_EMAIL_LOADING:
      return {
        ...state,
        emailLoading: true,
        error: false,
        errorMessage: "",
      };
    case actionTypes.GET_REQUESTS_SUCCESS:
      return {
        ...state,
        loading: false,
        requests: action.payload,
      };
    case actionTypes.GET_REQUESTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        requests: [],
      };

    case actionTypes.GET_EMAILS_BY_REQUEST_SUCCESS:
      return {
        ...state,
        emailLoading: false,
        emails: action.payload,
      };
    case actionTypes.GET_EMAILS_BY_REQUEST_FAILURE:
      return {
        ...state,
        emailLoading: false,
        error: true,
        errorMessage: action.payload,
        emails: [],
      };

    case actionTypes.ADD_TAKEDOWN_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
      };

    case actionTypes.ADD_TAKEDOWN_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        errorTitle: action.title,
        isSubscriptionActive: action.subscribed,
        dmcaActive: action.dmca,
      };

    case actionTypes.SEND_REPLY_SUCCESS:
      return {
        ...state,
      };
    case actionTypes.SEND_REPLY_FAILURE:
      return {
        ...state,
        emailLoading: false,
        error: true,
        errorMessage: action.payload,
      };

    case actionTypes.GET_ADDED_ASSET_TAKEDOWN_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        addedRequests:state.dataLength===undefined? action.payload.list:state.addedRequests.concat(action.payload.list),
        // totalPages:action.payload.total_pages,
        totalRecords:action.payload.total_records,
        // pageNumber:action.payload.page_number,
        // perPage:action.payload.per_page,
        dataLength:action.payload.list.length

      };

    case actionTypes.GET_ADDED_ASSET_TAKEDOWN_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
        errorTitle: action.title,
        isSubscriptionActive: action.isSubscriptionActive,
      };

    case actionTypes.START_LOADING_RESP:
      return {
        ...state,
        loadingResp: true,
      };

    case actionTypes.TAKEDOWN_ALL_LOADING:
      return {
        ...state,
        loading: true,
      };

    case actionTypes.TAKEDOWN_ALL_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };

    case actionTypes.TAKEDOWN_ALL_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
      };

    case actionTypes.START_TAKEDOWN_PROCESS_SUCCESS:
      return {
        ...state,
        loadingResp: false,
      };

    case actionTypes.START_TAKEDOWN_PROCESS_FAILURE:
      return {
        ...state,
        loadingResp: false,
        error: true,
        errorMessage: action.payload,
      };

    case actionTypes.ADD_DMCA_ORDER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.ADD_DMCA_ORDER_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action.payload,
      };

    case actionTypes.RESET_DMCA_ERROR:
      return {
        ...state,
        error: false,
        dmcaActive: false,
        isSubscriptionActive: false,
        errorMessage: "",
        errorTitle: "",
      };

    default:
      return state;
  }
};

export default DMCA;
