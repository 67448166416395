import * as actionTypes from "./actionTypes";

export const getRequestsAction = () => ({
  type: actionTypes.GET_REQUESTS,
});

export const getEmailsByRequestAction = (reqID) => ({
  type: actionTypes.GET_EMAILS_BY_REQUEST,
  reqID,
});

export const addTakedownRequestAction = (
  payload,
  successCallback,
  failCallback
) => ({
  type: actionTypes.ADD_TAKEDOWN_REQUEST,
  payload,
  successCallback,
  failCallback,
});

export const takedownAllAction = (
  successCallback,
) => ({
  type: actionTypes.TAKEDOWN_ALL,
  successCallback,
});

export const takedownMultiContent = (payload, successCallback) => ({
  type: actionTypes.TAKEDOWN_MULTI_CONTENT,
  payload,
  successCallback,
});

export const sendReplyAction = (payload) => ({
  type: actionTypes.SEND_REPLY,
  payload,
});

export const getAddedAssetTakeDownRequestAction = (payload) => ({
  type: actionTypes.GET_ADDED_ASSET_TAKEDOWN_REQUEST,
  payload
});

export const startTakedownProcessAction = (payload) => ({
  type: actionTypes.START_TAKEDOWN_PROCESS,
  payload,
});

export const addDmcaOrderDetailsAction = (payload, successCallback) => ({
  type: actionTypes.ADD_DMCA_ORDER_DETAILS,
  payload,
  successCallback,
});

export const resetDMCAError = () => ({
  type: actionTypes.RESET_DMCA_ERROR,
});
